// SchedulerEventEditModal.tsx
import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

interface SchedulerEventEditModalProps {
  showModal: boolean
  handleClose: () => void
  date: Date
  startTime: Date
  endTime: Date
  trainer: string
  lane: string
  branch: string
  setDate: (date: Date) => void
  setStartTime: (time: Date) => void
  setEndTime: (time: Date) => void
  setTrainer: (trainer: string) => void
  setLane: (lane: string) => void
  setBranch: (branch: string) => void
  handleSave: () => void
  handleDelete: () => void
  hourOptions: string[]
  trainerOptions: string[]
  laneOptions: string[]
  branchOptions: string[]
}

const SchedulerEventEditModal: React.FC<SchedulerEventEditModalProps> = ({
  showModal,
  handleClose,
  date,
  startTime,
  endTime,
  trainer,
  lane,
  branch,
  setDate,
  setStartTime,
  setEndTime,
  setTrainer,
  setLane,
  setBranch,
  handleSave,
  handleDelete,
  hourOptions,
  trainerOptions,
  laneOptions,
  branchOptions,
}) => (
  <Modal show={showModal} onHide={handleClose} className='scheduler-modal-custom'>
    <Modal.Header closeButton>
      <Modal.Title>Edit Event</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Label>Date</Form.Label>
        <Form.Group controlId='formEventDate'>
          <DatePicker
            selected={date}
            onChange={(date: Date | null) => setDate(date || new Date())}
            className='form-control'
            dateFormat='yyyy-MM-dd'
          />
        </Form.Group>
        <Form.Group controlId='formEventStart'>
          <Form.Label>Start Time</Form.Label>
          <Form.Control
            as='select'
            value={startTime.getHours()}
            onChange={(e) =>
              setStartTime(new Date(startTime.setHours(parseInt(e.target.value, 10))))
            }
          >
            <option value=''>Select Time</option>
            {hourOptions.map((hour, index) => (
              <option key={index} value={index}>
                {hour}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId='formEventEnd'>
          <Form.Label>End Time</Form.Label>
          <Form.Control
            as='select'
            value={endTime.getHours()}
            onChange={(e) => setEndTime(new Date(endTime.setHours(parseInt(e.target.value, 10))))}
          >
            <option value=''>Select Time</option>
            {hourOptions.map((hour, index) => (
              <option key={index} value={index}>
                {hour}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId='formEventTrainer'>
          <Form.Label>Trainer</Form.Label>
          <Form.Control as='select' value={trainer} onChange={(e) => setTrainer(e.target.value)}>
            <option value=''>Select Trainer</option>
            {trainerOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId='formEventLane'>
          <Form.Label>Lane</Form.Label>
          <Form.Control as='select' value={lane} onChange={(e) => setLane(e.target.value)}>
            <option value=''>Select Lane</option>
            {laneOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId='formEventBranch'>
          <Form.Label>Branch</Form.Label>
          <Form.Control as='select' value={branch} onChange={(e) => setBranch(e.target.value)}>
            <option value=''>Select Branch</option>
            {branchOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='secondary' onClick={handleClose}>
        Cancel
      </Button>
      <Button variant='danger' onClick={handleDelete}>
        Delete
      </Button>
      <Button variant='success' onClick={handleSave}>
        Save
      </Button>
    </Modal.Footer>
  </Modal>
)

export default SchedulerEventEditModal
