import React from 'react'
import { StatisticsWidget1 } from '../../../_metronic/partials/widgets'

function MaintenancePage() {
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-12'>
        <div className='col-xl-12'>
          <StatisticsWidget1
            className='card-xl-stretch mb-xl-8'
            image='abstract-4.svg'
            title=''
            time=''
            description='This page is under construction.'
          />
        </div>
      </div>
    </>
  )
}

export default MaintenancePage
