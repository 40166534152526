import React, { SyntheticEvent, useState } from 'react'
import UserEditModal from './UserEditModal'
import axios from 'axios'
import Swal from 'sweetalert2'
import UsersResultViewer from './UsersResultViewer'

function UsersList() {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  console.log('İnit itemIdForUpdate:', itemIdForUpdate)
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  let usersHeader = [
    { index: 1, type: 'field', key: 'id', title: 'ID', className: 'min-w-25px' },
    { index: 2, type: 'field', key: 'name', title: 'Name', className: 'min-w-125px' },
    { index: 3, type: 'field', key: 'email', title: 'Email', className: 'min-w-125px' },
    { index: 4, type: 'field', key: 'roles', title: 'Role', className: 'min-w-125px' },
    { index: 5, type: 'field', key: 'status', title: 'Status', className: 'min-w-125px' },
    { index: 6, type: 'field', key: 'actions', title: 'Actions', className: 'min-w-125px' },
  ]

  return (
    <>
      <UsersResultViewer
        title={'Users'}
        dataUrl={'/admin/users'}
        editCallback={(id: any, refresh: Function) => {
          console.log('Edit callback:', id)
          setRefreshFunction(() => refresh)

          setItemIdForUpdate(id)
        }}
        deleteCallback={(id: any, refresh: Function) => {
          Swal.fire({
            title: 'Warning',
            html: 'Are you want to delete this user?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-primary',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              axios.delete('admin/users/' + id).then(() => {
                refresh()
              })
            }
          })
        }}
        actionItems={[
          {
            text: 'Add New User',
            class: 'btn btn-sm btn-primary',
            icon: 'fa fa-user-plus',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              if (itemIdForUpdate !== undefined && itemIdForUpdate !== '') {
                setItemIdForUpdate(itemIdForUpdate)
              }
            },
          },
        ]}
        header={usersHeader}
      />
      {itemIdForUpdate !== '' ? (
        <UserEditModal
          itemIdForUpdate={itemIdForUpdate}
          show={itemIdForUpdate !== ''}
          onHide={() => {
            setItemIdForUpdate('')
          }}
          refreshFunction={refreshFunction}
        />
      ) : null}
    </>
  )
}

export default UsersList
