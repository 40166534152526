import React, { SyntheticEvent, useState } from 'react'
import ResultViewer from '../../utils/ResultViewer'
import ClubEditModal from './ClubEditModal'
import Swal from 'sweetalert2'
import axios from 'axios'

function ClubsList() {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  let clubsHeader = [
    { index: 1, type: 'field', key: 'id', title: 'ID', className: 'min-w-125px' },
    { index: 2, type: 'field', key: 'name', title: 'Name', className: 'min-w-125px' },
    { index: 3, type: 'field', key: 'email', title: 'Email', className: 'min-w-125px' },
    { index: 4, type: 'field', key: 'phone', title: 'Phone', className: 'min-w-125px' },
    { index: 5, type: 'field', key: 'actions', title: 'Actions', className: 'min-w-125px' },
  ]
  console.log('itemIdForUpdate:', itemIdForUpdate)

  return (
    <>
      <ResultViewer
        title={'Clubs'}
        dataUrl={'/admin/clubs'}
        editCallback={(id: any, refresh: Function) => {
          console.log('Edit callback:', id)
          setRefreshFunction(() => refresh)
          if (id !== undefined && id !== '') {
            setItemIdForUpdate(id)
          }
        }}
        deleteCallback={(id: any, refresh: Function) => {
          Swal.fire({
            title: 'Warning',
            html: 'Are you want to delete this club?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-primary',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              axios.delete('admin/clubs/' + id).then(() => {
                refresh()
              })
            }
          })
        }}
        actionItems={[
          {
            text: 'Add New Club',
            class: 'btn btn-sm btn-primary',
            icon: 'fa fa-golf-ball',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              if (itemIdForUpdate !== undefined) {
                setItemIdForUpdate('add')
              }
              setRefreshFunction(() => refresh)
            },
          },
        ]}
        header={clubsHeader}
      />
      {itemIdForUpdate !== '' ? (
        <ClubEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onClose={() => {
            setItemIdForUpdate('')
          }}
          refresh={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default ClubsList
