import React, { useState } from 'react'

function WeeklyScheduler() {
  const [currentWeek, setCurrentWeek] = useState(0)

  const dates = ['Date 1', 'Date 2', 'Date 3', 'Date 4', 'Date 5', 'Date 6', 'Date 7']

  const hours = ['Hour 1', 'Hour 2', 'Hour 3', 'Hour 4', 'Hour 5', 'Hour 6', 'Hour 7', 'Hour 8']

  const appointments = [
    [
      'Appointment 1',
      'Appointment 2',
      'Appointment 3',
      'Appointment 4',
      'Appointment 5',
      'Appointment 6',
      'Appointment 7',
    ],
    [
      'Appointment 8',
      'Appointment 9',
      'Appointment 10',
      'Appointment 11',
      'Appointment 12',
      'Appointment 13',
      'Appointment 14',
    ],
    // Add more rows as needed
  ]

  const handlePrevWeek = () => {
    setCurrentWeek(currentWeek - 1)
  }

  const handleNextWeek = () => {
    setCurrentWeek(currentWeek + 1)
  }

  return (
    <div className='container'>
      <div className='d-flex justify-content-between mb-3'>
        <select className='form-select me-2'>
          <option>Select Branch</option>
        </select>
        <select className='form-select me-2'>
          <option>Select Lane</option>
        </select>
        <select className='form-select me-2'>
          <option>Select Trainer</option>
        </select>
        <select className='form-select'>
          <option>Select Date</option>
        </select>
      </div>

      <div className='d-flex justify-content-between mb-3'>
        <button className='btn btn-primary' onClick={handlePrevWeek}>
          Prev Week
        </button>
        <button className='btn btn-primary' onClick={handleNextWeek}>
          Next Week
        </button>
      </div>

      <table className='table table-bordered'>
        <thead>
          <tr>
            <th></th>
            {dates.map((date, index) => (
              <th key={index}>{date}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {hours.map((hour, rowIndex) => (
            <tr key={rowIndex}>
              <td>{hour}</td>
              {appointments[rowIndex % appointments.length].map((appointment, colIndex) => (
                <td key={colIndex}>{appointment}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default WeeklyScheduler
