import axios from 'axios'

const token = localStorage.getItem('@auth/token')

export const fetchBranchList = async () => {
  console.log('Fetch Branch List Token:', token)
  try {
    const response = await axios.get('/admin/branches', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Branch list:', response.data['hydra:member'])
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((branch: any) => ({
        value: branch['@id'],
        label: branch.title,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching branch list:', error)
    throw error
  }
}

export const fetchClubList = async () => {
  try {
    const response = await axios.get('/admin/clubs', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Clubs list:', response.data['hydra:member'])
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((club: any) => ({
        value: club['@id'],
        label: club.name,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching club list:', error)
    throw error
  }
}

export const fetchLaneList = async () => {
  try {
    const response = await axios.get('/admin/lanes', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Lane list:', response.data['hydra:member'])
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((lane: any) => ({
        value: lane['@id'],
        label: lane.title,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching lane list:', error)
    throw error
  }
}

// Trainer rolüne göre filtreleme kısıtı eklenecek.
export const fetchTrainersList = async () => {
  try {
    const response = await axios.get('/admin/users', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Lane list:', response.data['hydra:member'])
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((user: any) => ({
        value: user['id'],
        label: user.name,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching trainer list:', error)
    throw error
  }
}
