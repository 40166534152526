import React, { useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import BranchEditModal from '../BranchEditModal'
import axios from 'axios'
import Swal from 'sweetalert2'

type Props = {
  className: string
  image: string
  title: string
  id: string
  refresh: Function
}

const BranchCard: React.FC<Props> = ({ className, image, title, id, refresh }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleEditClick = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleDeleteClick = async () => {
    try {
      const result = await Swal.fire({
        title: 'Warning',
        html: 'Are you want to delete this branch?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary',
        },
      })

      if (result.isConfirmed) {
        const response = await axios.delete(
          `https://test.rmgacademy.co.uk/api/admin/branches/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
            },
          }
        )
        console.log('Branch deleted:', response.data)
        Swal.fire('Deleted!', 'Your branch has been deleted.', 'success')
        refresh(true)
      }
    } catch (error) {
      console.error('Error deleting branch:', error)
      Swal.fire('Error!', 'There was an error deleting the branch.', 'error')
    }
  }

  return (
    <div className={`card bgi-no-repeat ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex justify-content-between align-items-center'>
        <a href='#' className='card-title fw-bold text-muted text-hover-primary fs-4'>
          {title}
        </a>
        <div>
          <a
            href='#'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={handleEditClick}
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </a>
          <a
            href='#'
            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
            onClick={handleDeleteClick}
          >
            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
          </a>
        </div>
      </div>
      {/* end::Body */}

      {/* BranchEditModal */}
      {isModalOpen && (
        <BranchEditModal id={id} onClose={handleCloseModal} show={isModalOpen} refresh={refresh} />
      )}
    </div>
  )
}

export default BranchCard
