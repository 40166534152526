export const userRoles = [
  { value: 'ROLE_USER', label: 'User' },
  { value: 'ROLE_TRAINER', label: 'Trainer' },
  { value: 'ROLE_CLUB_OWNER', label: 'Club Owner' },
  { value: 'ROLE_ADMIN', label: 'Admin' },
  { value: 'ROLE_SUPER_ADMIN', label: 'Super Admin' },
]

export const userStatus = [
  { value: 'Active', label: 'Active' },
  { value: 'Blocked', label: 'Blocked' },
  { value: 'Banned', label: 'Banned' },
]
