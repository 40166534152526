import { lazy, FC, Suspense, useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import SupportPage from '../modules/support/SupportPage'
import { meApi } from '../modules/auth/core/_requests'
import { User } from '../modules/auth/core/_models'
import DashboardPage from '../modules/dashboard/DashboardPage'
import BranchesPage from '../modules/branches/BranchesPage'
import ClubsPage from '../modules/clubs/ClubsPage'
import LanesPage from '../modules/lanes/LanesPage'
import UsersPage from '../modules/users/UsersPage'
import PlacesPage from '../modules/places/PlacesPage'
import SchedulerPage from '../modules/scheduler/SchedulerPage'
import MaintenancePage from '../modules/dashboard/MaintenancePage'

const PrivateRoutes = () => {
  //const UsersPage = lazy(() => import('../modules/users/UsersPage'))
  const [user, setUser] = useState<any>()
  const [token, setToken] = useState<string | undefined>(() => {
    return localStorage.getItem('@auth/token') ?? undefined
  })
  let [hasValidRole, setHasValidRole] = useState(0)
  useEffect(() => {
    const fetchUser = async () => {
      const user = await meApi(token) // meApi'yi çağırarak kullanıcı bilgilerini alıyoruz
      if (!user) {
        setToken(undefined)
        return
      }
      if (
        (user.roles && user.roles.includes('ROLE_SUPER_ADMIN')) ||
        user.roles.includes('ROLE_ADMIN')
      ) {
        setHasValidRole(1)
        console.log('hasValidRole:', hasValidRole)
      }
      setUser(user.userIdentifier)
    }
    fetchUser()
  }, [])
  hasValidRole = 1
  if (hasValidRole === 1) {
    console.log('ÖMustafa:', hasValidRole)
    return (
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route
            path='dashboard/*'
            element={
              <SuspensedView>
                <DashboardPage />
              </SuspensedView>
            }
          />
          <Route
            path='users/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          <Route
            path='branches/*'
            element={
              <SuspensedView>
                <BranchesPage />
              </SuspensedView>
            }
          />
          <Route
            path='clubs/*'
            element={
              <SuspensedView>
                <ClubsPage />
              </SuspensedView>
            }
          />
          <Route
            path='lanes/*'
            element={
              <SuspensedView>
                <LanesPage />
              </SuspensedView>
            }
          />
          <Route
            path='places/*'
            element={
              <SuspensedView>
                <PlacesPage />
              </SuspensedView>
            }
          />
          <Route
            path='scheduler/*'
            element={
              <SuspensedView>
                <SchedulerPage />
              </SuspensedView>
            }
          />
          <Route
            path='under-construction/*'
            element={
              <SuspensedView>
                <MaintenancePage />
              </SuspensedView>
            }
          />
          <Route
            path='support/*'
            element={
              <SuspensedView>
                <SupportPage />
              </SuspensedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    )
  }
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route
          path='dashboard/*'
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
