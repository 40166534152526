import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, InputGroup } from 'react-bootstrap'
import { fetchBranchList, fetchClubList } from '../../utils/SelectListMethods'
import { userRoles, userStatus } from '../../utils/ConstVariables'

type UserEditModalProps = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: () => void
}

class User {
  name?: string
  email?: string
  password?: string
  status?: string
  clubs?: Array<string>
  branches?: Array<string>
  roles: Array<string> = []
}

const UserEditModal: React.FC<UserEditModalProps> = ({
  onHide,
  show,
  itemIdForUpdate,
  refreshFunction,
}) => {
  const [formData, setFormData] = useState<User>({
    name: '',
    email: '',
    password: '',
    status: '',
    clubs: [],
    branches: [],
    roles: [],
  })

  const [clubs, setClubs] = useState<any[]>([])
  const [branchOptions, setBranchOptions] = useState<any[]>([])
  const roles = userRoles
  const statusOptions = userStatus

  const fetchClubs = async () => {
    fetchClubList()
      .then((response) => {
        setClubs(response)
      })
      .catch((error) => {
        console.error('Error fetching clubs:', error)
      })
  }

  const fetchBranches = async () => {
    fetchBranchList()
      .then((response) => {
        setBranchOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching branches:', error)
      })
  }

  const fetchSingleData = async () => {
    if (itemIdForUpdate !== 'new') {
      axios
        .get(`/admin/users/${itemIdForUpdate}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
          },
        })
        .then((response) => {
          setFormData({
            name: response.data.name,
            email: response.data.email,
            password: '',
            status: response.data.status,
            clubs: response.data.clubs,
            branches: response.data.branch,
            roles: response.data.roles,
          })
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    } else {
      setFormData({
        name: '',
        email: '',
        password: '',
        status: '',
        clubs: [],
        branches: [],
        roles: [],
      })
    }
  }

  const updateData = async () => {
    axios
      .put(`/admin/users/${itemIdForUpdate}`, formData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        console.log('Data updated:', response.data)
        onHide()
      })
      .catch((error) => {
        console.error('Error updating data:', error)
      })
  }

  const createData = async () => {
    axios
      .post(`/admin/users`, formData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onHide()

        setFormData({
          name: '',
          email: '',
          password: '',
          status: '',
          clubs: [],
          branches: [],
          roles: [],
        })
      })
      .catch((error) => {
        console.error('Error creating data:', error)
      })
  }

  useEffect(() => {
    if (itemIdForUpdate !== 'new') {
      fetchSingleData()
    }
    fetchClubs()
    fetchBranches()
  }, [itemIdForUpdate])
  console.log('Clubs:', clubs)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }
  const handleMultipleSelectChange = (event: any) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option: any) => option.value)
    setFormData({ ...formData, roles: selectedOptions })
  }
  const handleMultipleCheckSelectChange = (event: any) => {
    const { value, checked } = event.target
    const newRoles = checked
      ? [...formData.roles, value]
      : formData.roles.filter((role: any) => role !== value)
    setFormData({ ...formData, roles: newRoles })
  }
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (itemIdForUpdate === 'new') {
      createData()
      refreshFunction && refreshFunction()
    } else if (itemIdForUpdate !== 'new' && itemIdForUpdate !== '') {
      updateData()
      refreshFunction && refreshFunction()
    }
  }
  const [showPassword, setShowPassword] = useState(false)

  const handlePasswordChange = (event: any) => {
    setFormData({ ...formData, password: event.target.value })
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Modal show={show} onHide={onHide} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{itemIdForUpdate === 'new' ? 'Add User' : 'Edit User'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='formUserName'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='formUserEmail'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='formUserPassword'>
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                name='password'
                value={formData.password}
                onChange={handlePasswordChange}
                required
              />
              ...
            </InputGroup>
          </Form.Group>
          <Form.Group controlId='formUserClubs'>
            <Form.Label>Clubs</Form.Label>
            <Form.Select name='clubs' value={formData.clubs} onChange={handleSelectChange} required>
              <option value=''>Select club</option>
              {clubs.map((club) => (
                <option key={club.value} value={club.value}>
                  {club.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formUserBranches'>
            <Form.Label>Branches</Form.Label>
            <Form.Select
              name='branches'
              value={formData.branches}
              onChange={handleSelectChange}
              required
            >
              <option value=''>Select branch</option>
              {branchOptions.map((branch) => (
                <option key={branch.value} value={branch.value}>
                  {branch.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formUserRoles'>
            <Form.Label>Roles</Form.Label>
            {roles.map((role) => (
              <Form.Check
                type='checkbox'
                key={role.value}
                label={role.label}
                value={role.value}
                checked={formData.roles?.includes(role.value)}
                onChange={handleMultipleCheckSelectChange}
                style={{ marginBottom: '10px' }}
              />
            ))}
          </Form.Group>
          <Form.Group controlId='formUserStatus'>
            <Form.Label>Status</Form.Label>
            <Form.Select
              name='status'
              value={formData.status}
              onChange={handleSelectChange}
              required
            >
              <option value=''>Select status</option>
              {statusOptions.map((status) => (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {/* Add more form fields */}
          <div className='text-end mt-3'>
            <Button variant='secondary' onClick={onHide} className='me-2'>
              Cancel
            </Button>
            <Button type='submit' variant='primary'>
              Save changes
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default UserEditModal
