import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import ClubsList from './ClubsList'

const clubsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Clubs',
    path: '/clubs',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ClubsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Clubs'}></DocumentTitle>
              <PageTitle breadcrumbs={clubsBreadcrumbs}>List</PageTitle>
              <ClubsList />
            </>
          }
        />
        <Route index element={<Navigate to='/clubs/list' />} />
      </Route>
    </Routes>
  )
}

export default ClubsPage
