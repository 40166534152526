import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { fetchBranchList, fetchLaneList, fetchTrainersList } from '../../utils/SelectListMethods'

interface ToolbarProps {
  dateFilterType: 'range' | 'single'
  selectedDate: Date | [Date, Date]
  selectedBranch: string
  selectedLane: string
  selectedTrainer: string
  trainerFilterShow: boolean
  onDateChange: (date: Date | [Date, Date]) => void
  onBranchChange: (branch: string) => void
  onLaneChange: (lane: string) => void
  onTrainerChange: (trainer: string) => void
}

const SchedulerToolbar: React.FC<ToolbarProps> = ({
  dateFilterType,
  selectedDate,
  selectedBranch,
  selectedLane,
  selectedTrainer,
  trainerFilterShow,
  onDateChange,
  onBranchChange,
  onLaneChange,
  onTrainerChange,
}) => {
  const [branchOptions, setBranchOptions] = React.useState<any[]>([])
  const [laneOptions, setLaneOptions] = React.useState<any[]>([])
  const [trainerOptions, setTrainerOptions] = React.useState<any[]>([])

  const getLaneOptions = async () => {
    try {
      const response = await fetchLaneList()
      setLaneOptions(response)
    } catch (error) {
      console.error('Error fetching lane list:', error)
    }
  }

  const getTrainerOptions = async () => {
    try {
      const response = await fetchTrainersList()
      setTrainerOptions(response)
    } catch (error) {
      console.error('Error fetching trainers list:', error)
    }
  }

  const getBranchOptions = async () => {
    fetchBranchList()
      .then((response) => {
        setBranchOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching branch list:', error)
      })
  }

  useEffect(() => {
    getBranchOptions()
    getLaneOptions()
    getTrainerOptions()
  }, [])

  return (
    <div className='d-flex justify-content-between mb-3'>
      <select
        className='form-select me-2'
        value={selectedBranch}
        onChange={(e) => onBranchChange(e.target.value)}
      >
        <option>Select Branch</option>
        {branchOptions.map((branch) => (
          <option key={branch.value} value={branch.value}>
            {branch.label}
          </option>
        ))}
      </select>

      <select
        className='form-select me-2'
        value={selectedLane}
        onChange={(e) => onLaneChange(e.target.value)}
      >
        <option>Select Lane</option>
        {laneOptions.map((lane) => (
          <option key={lane.value} value={lane.value}>
            {lane.label}
          </option>
        ))}
      </select>

      {trainerFilterShow && (
        <select
          className='form-select me-2'
          value={selectedTrainer}
          onChange={(e) => onTrainerChange(e.target.value)}
        >
          <option>Select Trainer</option>
          {trainerOptions.map((trainer) => (
            <option key={trainer.value} value={trainer.value}>
              {trainer.label}
            </option>
          ))}
        </select>
      )}

      <div className='form-input datepicker-container'>
        {dateFilterType === 'range' ? (
          <DateRange
            ranges={[
              {
                startDate: (selectedDate as [Date, Date])[0],
                endDate: (selectedDate as [Date, Date])[1],
                key: 'selection',
              },
            ]}
            onChange={(ranges) => {}}
            moveRangeOnFirstSelection={false}
            rangeColors={['#3d91ff']}
          />
        ) : (
          <input
            type='date'
            value={(selectedDate as Date).toISOString().substr(0, 10)}
            onChange={(e) => onDateChange(new Date(e.target.value))}
            className='form-control'
          />
        )}
      </div>
    </div>
  )
}

export default SchedulerToolbar
