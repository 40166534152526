import React, { useState } from 'react'
import {
  MixedWidget9,
  MixedWidget11,
  MixedWidget10,
  StatisticsWidget5,
  MixedWidget8,
} from '../../../_metronic/partials/widgets'
import SchedulerToolbar from './SchedulerToolbar'

function DummyDashboard() {
  const [startTime, setStartTime] = useState<Date | undefined>(undefined)
  const [endTime, setEndTime] = useState<Date | undefined>(undefined)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [selectedBranch, setSelectedBranch] = useState<string>('')
  const [selectedLane, setSelectedLane] = useState<string>('')
  const [selectedTrainer, setSelectedTrainer] = useState<string>('')
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen025.svg'
            color='white'
            iconColor='primary'
            title='3'
            description='Branches'
          />
        </div>
        <div className='col-xl-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen017.svg'
            color='white'
            iconColor='primary'
            title='12'
            description='Places'
          />
        </div>

        <div className='col-xl-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen059.svg'
            color='white'
            iconColor='primary'
            title='67'
            description='Lanes'
          />
        </div>
        <div className='col-xl-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen056.svg'
            color='white'
            iconColor='primary'
            title='3'
            description='Clubs'
          />
        </div>
        <div className='col-xl-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen049.svg'
            color='white'
            iconColor='primary'
            title='67'
            description='Trainers'
          />
        </div>
        <div className='col-xl-2'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen049.svg'
            color='white'
            iconColor='primary'
            title='12'
            description='Users'
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-4'>
          <MixedWidget11
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='200px'
            title='Booking'
            count='227'
            date=''
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <MixedWidget11
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='200px'
            title='Users'
            count='150'
            date=''
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <MixedWidget11
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='200px'
            title='Hours'
            count='200'
            date=''
          />
        </div>
        {/* end::Col */}
      </div>
      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-4'>
          <MixedWidget9
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <MixedWidget9
            className='card-xl-stretch mb-xl-8'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <MixedWidget9
            className='card-xl-stretch mb-xl-8'
            chartColor='danger'
            chartHeight='150px'
          />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
      {/* end::Row */}
      <div className='toolbar'>
        <SchedulerToolbar
          dateFilterType={'single'} // Changed to single date selection
          selectedDate={selectedDate}
          selectedBranch={selectedBranch}
          selectedLane={selectedLane}
          selectedTrainer={selectedTrainer}
          trainerFilterShow={true}
          onDateChange={(date) => setSelectedDate(date as Date)}
          onBranchChange={(branch) => setSelectedBranch(branch)}
          onLaneChange={(lane) => setSelectedLane(lane)}
          onTrainerChange={(trainer) => setSelectedTrainer(trainer)}
        />
      </div>
      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-6'>
          <MixedWidget11
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='200px'
            title='Booking History'
            count='17'
            date=''
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-6'>
          <MixedWidget11
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='200px'
            title='Hours'
            count='150'
            date=''
          />
        </div>

        {/* end::Col */}
      </div>

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-4'>
          <MixedWidget10
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <MixedWidget10
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <MixedWidget10
            className='card-xl-stretch mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
          />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

export default DummyDashboard
